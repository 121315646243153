import React, {useState} from "react";
import {Button, Card, Col, Container, Image, ListGroup, Modal, Row} from "react-bootstrap";
import "./members.css"

const Members = () => {
    const [listStatus, setListStatus] = useState(0)
    const [memberStatus, setMemberStatus] = useState(0)
    const [modelShow, setModelShow] = useState(false)
    const handleClose = () => setModelShow(false);
    const handleShow = () => setModelShow(true);
    const listData = ['Principal Investigator', 'Assistant Investigator', 'Postdoctoral Fellow', 'Academic Advisor', 'Student Fellow', 'Visiting Student', 'Alumni',]
    const membersList = [
        // {
        //     img: '/images/boss2.png',
        //     name: 'YongFan Men (门涌帆)',
        //     education: '',
        //     person_experience: '',
        //     email: 'yf.men@siat.ac.cn',
        //     positionID: 0
        // },
        {
            name: ' TingTing Ao (敖婷婷)',
            education: '  M.E. In Chemical Engineering, Xiangtan University, 2012\n' +
                '  B.E. In Bioengineering, Xiangntan University, 2009',
            person_experience: '  Research Engineer- Microfluidics In Menyongfan Group,Institute Of Biomedical And Health Engineering Bionic Tactile Sensing And Intelligent Center(Bsic), Shenzhen Institutes Of Advanced Technology,Chinese Academy Of Sciences, 2017-2020\n' +
                '  Technician - Microfluidics In Huangyanyi Group, Biopic(Biomedical Pioneering Innovation Center),Peking University，2012-2014',
            email: 'tt.ao@siat.ac.cn',
            positionID: 6
        },
        {
            img: '/images/mem2.png',
            name: 'Wen Li (李 雯)',
            education: '  2013, Ph.D, The Chinese University of Hong Kong\n' +
                '  2009, M.M, Kunming Medical University\n' +
                '  2005, B.Sc, Henan University',
            person_experience: ' 2018, Postdoctoral fellow, Yongfan MEN group, Shenzhen Institutes of Advanced Technology (SIAT), Chinese Academy of Sciences\n' +
                '  2016, Research Assistant, School of Life Sciences. The Chinese University of Hong Kong',
            email: 'wen.li1@siat.ac.cn',
            positionID: 6
        },

        {

            name: ' TianCong Zhao (赵天聪)',
            education: '  2017-2020 Master of Engineering, Ship and Ocean Engineering, Dalian Maritime University\n' +
                '  2013-2017 Bachelor Degree in Marine Engineering, School of Marine Engineering, Dalian Maritime University',
            person_experience: '  Majoring in master\'s degree--Dalian Maritime University, School of Marine Engineering, Marine and Ocean Engineering',
            email: 'tiancongzhao1484@yahoo.com',
            positionID: 6
        }, {
            img: '/images/bu2.png',
            name: ' Wenting Bu (布文婷)',
            education: '  2014-2018, Ph.D, Nanyang Technological University(NTU), Singapore\n' +
                '  2008-2012, B.Sc, Jilin University, Changchun',
            person_experience: ' 2020-now, Postdoctoral fellow, MEN Lab, Shenzhen Institute of Advanced Technology (SIAT), Chinese Academy of Sciences\n' +
                '  2018-2020, Research fellow, Gao Lab, Nanyang institute of Structural Biology, NTU\n' +
                '  2012-2013, Research assistant, Yu Lab, School of Life Science, Tsinghua University',
            email: 'wt.bu@siat.ac.cn',
            positionID: 1
        },
        {

            name: 'BiZhu Wu (吴碧珠)',
            education: '  I received my Bachelor\'s degree from Shenzhen University. I have joined the Men laboratory as a visiting student for more than 2 years.',
            person_experience: '  During this period, most of my job is center on programming. I am so lucky to gain my first opportunity in engaging laboratory work when I am a sophomore. I have learned a lot from this experience.',
            email: '',
            positionID: 6
        },
        {

            name: ' Xuan Zheng (郑 萱)',
            education: '  I am a senior student from Shenzhen University. \n' +
                '  I have joined the Men Laboratory as a visting student for a year.',
            person_experience: '  It\'s my honor to get this Internship so that I can meet many excellent colleagues and obtain many resources. Here, I mainly focus on GUI Coding although something I haven\'t learnt before. It\'s difficult sometimes but I still have to thank these challenges which make me feel like I have constantly improved after solving them. What\'s more, accessing knowledge in new fields and doing some Biological engineering experiments are also interesting for me.',
            email: '',
            positionID: 6
        },
        {

            name: 'Weijun Kong (孔维俊)',
            education: '  2019, Ph.D, University of Science and Technology of China\n' +
                '  2014, M.M, Southwest University',
            person_experience: '  2020, Postdoctoral fellow, Yongfan MEN group, Shenzhen Institutes of Advanced Technology (SIAT), Chinese Academy of Sciences.\n' +
                '  2015, Teaching assistant, University of Science and Technology of China.',
            email: 'wj.kong@siat.ac.cn',
            positionID: 6
        },
        {

            name: ' Yuxiang Xiong（熊宇翔）',
            education: '  I have joined the Men Laboratory since I was a sophomore from Shenzhen University ',
            person_experience: '  Thanks to the Men Laboratory for providing me such a precious opportunity to be better contact with advanced technologies in cutting-edge scientific research fields。Hoping that I can contribute my strength in biological experiments related to my profession and try my best to learn programming knowledge to better fit the projects.',
            email: '',
            positionID: 6
        },
        {

            name: ' JianYan Qu（区锦妍）',
            education: '  I am a sophomore student from Shenzhen University. I just joined the Men lab as a visiting student.',
            person_experience: '',
            email: '',
            positionID: 6
        },
        {
            img: '/images/fanyong2.png',
            name: ' Yong Fan(范勇)',
            education: '  2016-2022, Ph.D, Nagoya University, Japan \n' +
                '  2013-2016, B.sc, Tianjin University of Technology, Tianjin',
            person_experience: '  2013-2022, mainly engaged in biomechanical research, including vascular biomechanics, \n' +
                '   hemodynamics etc. My current research field is plant biomechanics. \n',
            email: 'y.fan@siat.ac.cn',
            positionID: 2
        },
        {
            img: '/images/nie.png',
            name: ' Nie Jun(聂俊)',
            education: '  2020-2023, Assistant Research Fellow, Shenzhen Bay Labratory, China \n' +
                '  2015-2020, Ph.D, Huazhong University of Sicence and Technology, China\n' +
                '  2011-2015, B.S., Huazhong University of Sicence and Technology, China',
            person_experience: '  Primarily engaged in the field of biophotonic imaging, focused on high-throughput three- \n' +
                'dimensional fluorescence microscopy research. Developed a high-resolution multi-view \n' +
                'sub-voxel microscopy that enables high-resolution three-dimensional imaging of zebrafish, \n' +
                'mouse brains, and more. Also developed an inverted microscopy that enables fast three- \n' +
                'dimensional imaging of large-scale 3D samples. These technologies provide a powerful \n' +
                'tool for biomedical research.',
            email: 'j.nie@siat.ac.cn',
            positionID: 2
        },
        {
            img: '/images/jiaming.png',
            name: '  Jiaming Peng（彭嘉明）',
            education: '  2022-2026，B.Sc，Shenzhen University，Shenzhen',
            person_experience: '  After joining in Men laboratory，l find interdisciplinary more and more frequent during daily experiments and projects. Electron, Machine and Internet play gradually a crucial role in biological subject. Hoping that I can learn multi subjects in different angles to focus on biological problems.',
            email: '2022300049@email.szu.edu.cn',
            positionID: 5
        },
        {
            img: '/images/myPhoto2.png',
            name: '  Jichen Li (李纪辰)',
            education: '  2022-2025，M.Sc，University of Science and Technology of China（USTC）,Hefei \n' +
                '  2018-2022，B.Sc，Anhui University of Technology（AHUT），Maanshan \n',
            person_experience: '  His research interests are droplet generation based on microfluidic and injection molding materials under normal temperature and pressure. His current research focuses on the preparation of hydrogel microspheres and their applications in 3D printing.',
            email: '1243315@mail.sustech.edu.cn',
            positionID: 4
        },
        {
            img: '/images/xiaoyue.png',
            name: '  XiaoYue Luo(罗潇月)',
            education: '  2024-now M.E,  Southern University of Science and Technology  ,ShenZheng \n' +
                '  2020-2024 B.SC,  LanZhou University,  LanZhou \n',
            person_experience: '',
            email: '1243315@mail.sustech.edu.cn',
            positionID: 4
        },
        {
            img: '/images/myPhoto.png',
            name: '  Fuchun Li(李福春)',
            education: '  2024-now M.E,  Southern University of Science and Technology  ,ShenZheng \n' +
                '  2020-2024 B.SC,  Yangtze University, Jingzhou \n',
            person_experience: '',
            email: '12433357@mail.sustech.edu.cn',
            positionID: 4
        },

    ]
    const publicationsList = [
        {
            id: 0,
            comment: 'Zhenyu Wang, Qiuhua Yang, Dan Zhang, Yuanyi Lu, Yichuan Wang, Yajie Pan, Yuping Qiu, Yongfan Men, ' +
                'Wei Yan, Zhina Xiao, Ruixue Sun, Wenyang Li, Hongda Huang1, and Hongwei Guo1*, ' +
                'A Cytoplasmic Osmosensing Mechanism Mediated by Molecular Crowding-Sensitive DCP5. Science, doi: 10.1126/science.adk9067'
        },
        {
            id: 1,
            comment: 'Jichen Li, Wen Li, Bizhu Wu, Wenting Bu, Miaomiao Li, Jinyan Ou, Yuxiang Xiong, Shangtao Wu, Yanyi Huang, Yong Fan*, Yongfan Men*, An Injection-Mold Based Method with a Nested Device for Microdroplet Generation by Centrifugation. Processes, 2024, 12(3), 483. doi: 10.3390/pr12030483'
        },
        {
            id: 2,
            comment: 'Weijun Kong, Hongtao Feng, Xiang Qian, Yizhao Chen, Mengying Deng, Pengfei Zhang, Wen Li, Wenting Bu, Wenchao Xu, Wei Jin, Yuqing Huang, Jun Chu, Shangtao Wu, Yan Chen, Yongfan Men*, Facile and scalable generation of fluorescent microspheres using a microfluidic electrojetting device. Sensors and Actuators B: Chemical, 2023, 133106. doi: 10.1016/j.snb.2022.133106.'
        },
        {
            id: 3,
            comment: 'Xiannian Zhang, Zhenwei Qian, Mengcheng Jiang, Wentao Li, Yanyi Huang, Yongfan Men*, Design and High-Resolution Analysis of an Efficient Periodic Split-and-Recombination Microfluidic Mixer. Micromachines 13 (10), 1720 (2022). doi:10.3390/mi13101720'
        },
        {
            id: 4,
            comment: 'Li Liu, Wen Song, Shijia Huang, Kai Jiang, Yoshitaka Moriwaki, Yichuan Wang, Yongfan Men, Dan Zhang, Xing Wen, Zhifu Han, Jijie Chai, Hongwei Guo, Extracellular pH sensing by plant cell-surface peptide-receptor complexes. Cell 185 (18), 3341-3355. e13 (2022). doi:10.1016/j.cell.2022.07.012'
        },
        {
            id: 5,
            comment: 'Wen Li, Lingxiao Chaihu, Jialu Jiang, Bizhu Wu, Xuan Zheng, Rongrong Dai, Ye Tian, Yanyi Huang, Guanbo Wang, Yongfan Men*, Microfluidic Platform for Time-Resolved Characterization of Protein Higher-Order Structures and Dynamics Using Top-Down Mass Spectrometry. Analytical Chemistry 94, 21, 7520–7527 (2022). doi:10.1021/acs.analchem.2c00077'
        },
        {
            id: 6,
            comment: 'Zhiyi Yang, Wenbo Li, Hao Huang, Songlei Ren, Yongfan Men, Fang Li, Xuefeng Yu, Qian Luo, Detection of serum phospholipids by microchannel-integrated black phosphorus-assisted laser desorption/ionization mass spectrometry. Talanta 237, 122978 (2022). doi:10.1016/j.talanta.2021.122978'
        },
        {
            id: 7,
            comment: 'Wen Tan, Tingrui Pan, Shengpeng Wang, Peng Li, Yongfan Men, Rui Tan, Zhangfeng Zhong, Yitao Wang, Immunometabolism modulation, a new trick of edible and medicinal plants in cancer treatment. Food Chemistry 376, 131860 (2021). doi:10.1016/j.foodchem.2021.131860'
        },
        {
            id: 8,
            comment: 'Wenting Bu, Wen Li, Jiannan Li, Tingting Ao, Zhihao Li, Bizhu Wu, Shangtao Wu, Weijun Kong, Tingrui Pan, Yi Ding, Wen Tan, Baoqing Li, Yan Chen, Yongfan Men*, A low-cost, programmable, and multi-functional droplet printing system for low copy number SARS-CoV-2 digital PCR determination. Sensors and Actuators B: Chemical 348, 130678 (2021). doi:10.1016/j.snb.2021.130678'
        },
        {
            id: 9,
            comment: 'Xuelin Huang, Zhe Jiao, Zongning Guo, Jialing Yang, Parvej Alam, Yong Liu, Yongfan Men, Pengfei Zhang, Haitao Feng, Shuhuai Yao, Ben Zhong Tang, Development of Reaction-Based AIE Handy Pen for Visual Detection of Toxic Vapors. ACS Materials Letters 3 (2), 249-254 (2021). doi:10.1021/acsmaterialslett.0c00516'
        },
        {
            id: 10,
            comment: 'Xiangzhong Sun, Yuping Qiu, Yang Peng, Juewei Ning, Guangjie Song, Yanzhu Yang, Mengyu Deng, Yongfan Men, Xingzhong Zhao, Yichuan Wang, Hongwei Guo, Yanqing Tian, Close Temporal Relationship between Oscillating Cytosolic K+ and Growth in Root Hairs of Arabidopsis. International journal of molecular sciences 21 (17), 6184 (2020). doi.org/10.3390/ijms21176184'
        },
        {
            id: 11,
            comment: 'Yang Pan, Tuo Ma, Qi Meng, Yuxin Mao, Kaiqin Chu, Yongfan Men, Tingrui Pan, Baoqing Li, Jiaru Chu, Droplet digital PCR enabled by microfluidic impact printing for absolute gene quantification. Talanta 211, 120680 (2019). doi:10.1016/j.talanta.2019.120680'
        },
        {
            id: 12,
            comment: 'Wen Tan, Zhangfeng Zhong, Randy P Carney, Yongfan Men, Jiannan Li, Tingrui Pan, Yitao Wang. Deciphering the metabolic role of AMPK in cancer multi-drug resistance. Seminars in Cancer Biology 56, 56-71 (2019). doi:10.1016/j.semcancer.2018.09.005'
        },
        {
            id: 13,
            comment: 'Jiannan Li, Wen Tan, Wenwu Xiao, Randy P Carney, Yongfan Men, Yuanpei Li, Gerald Quon, Yousif Ajena, Kit S Lam, Tingrui Pan, A plug-and-play, drug-on-pillar platform for combination drug screening implemented by microfluidic adaptive printing. Analytical chemistry 90, 13969-13977 (2018). doi:10.1021/acs.analchem.8b03456 '
        },
        {
            id: 14,
            comment: 'Jinzhen Fan, Yongfan Men, Kuo Hao Tseng, Yi Ding, Yunfeng Ding, Fernando Villarreal, Cheemeng Tan, Baoqing Li, Tingrui Pan, Dotette: Programmable, high-precision, plug-and-play droplet pipetting. Biomicrofluidics 12, 034107 (2018). doi:10.1063/1.5030629'
        },
        {
            id: 15,
            comment: 'Zehao Pan, Yongfan Men, Satyayoti Senapati, and Hsueh-Chia Chang. Immersed AC electrospray (iACE) for monodispersed aqueous droplet generation. Biomicrofluidics 12 , 044113 (2018). doi:10.1063/1.5048307'
        },
        {
            id: 16,
            comment: 'Meiling Zhang, Tao Sun, Chongshu Jian, Lei Lei, Peidong Han, Quanlong Lv, Ran Yang, Xiaohai Zhou, Jiejia Xu, Yingchun Hu, Yongfan Men, Yanyi Huang, Chuanmao Zhang, Xiaojun Zhu, Xianhua Wang, Heping Cheng,* Jing- Wei Xiong*.\n' +
                'Remodeling of Mitochondrial Flashes in Muscular Development and Dystrophy in Zebrafish. PLOS ONE 10 (7), e0132567 (2015) . doi: 10.1371/journal.pone.0132567'
        },
        {
            id: 17,
            comment: 'Bo Xian, Jie Shen, Weiyang Chen, Na Sun, Nan Qiao, Dongqing Jiang, Tao Yu, Yongfan Men, Zhijun Han, Yuhong Pang, Matt Kaeberlein, Yanyi Huang, Jing-Dong J. Han, WormFarm: A quantitative control and measurement device towards automated C. elegans aging analysis. Aging Cell 12 (3), 398-409 (2013) . doi: 10.1111/acel.12063'
        },
        {
            id: 18,
            comment: 'Yongfan Men, Yusi Fu, Zitian Chen, Peter A. Sims, William J. Greenleaf, Yanyi Huang. Digital polymerase chain reaction in an array of femtoliter PDMS microreactors. Analytical Chemistry 84 (10), 4262-4266 (2012) . doi: 10.1021/ac300761n'
        },
        {
            id: 19,
            comment: 'Yongfan Men, Qiang Yu, Zitian Chen, Jianbin Wang, Yanyi Huang, Hongwei Guo.\n' +
                'A high-throughput imaging system to quantitatively analyze the growth dynamics of plant seedlings. Integrative Biology 4 (8), 945-952 (2012) . doi: 10.1039/C2IB20020A'
        },
        {
            id: 20,
            comment: 'Peng Fei, Zitian Chen, Yongfan Men, Ang Li, Yiran Shen, Yanyi Huang. A Compact Optofluidic Cytometer with Integrated Liquid-core/PDMS-cladding Waveguides. Lab on a Chip 12 (19), 3700-3706 (2012) . doi: 10.1039/C2LC40329C'
        },
        {
            id: 21,
            comment: 'Peng Fei, Zi He, Chunhong Zheng, Tao Chen, Yongfan Men, Yanyi Huang. Discretely Tunable Optofluidic Compound Microlenses. Lab on a Chip 11 (17), 2835-2841 (2011) . doi: 10.1039/c1lc20425d'
        },
        {
            id: 22,
            comment: 'Ke Wang , Lianping Niu , Zhaocun Zong , Mingzhe Zhang, Chen Wang, Xinjing Shi, Yongfan Men and Guangtian Zou. Direct Electrodeposition of Copper Ladder Structures on a Silicon Substrate. Crystal Growth Design 8 (2), 442–445 (2008). doi:10.1021/cg070200n'
        },
        {
            id: 23,
            comment: 'Zhaocun Zong, Hai Yu, Lianping Nui, Mingzhe Zhang, Chen Wang, Wei Li, Yongfan Men, Binbin Yao and Guangtian Zou. Potential-induced copper periodic micro-/nanostructures by electrodeposition on silicon substrate. Nanotechnology 19 (31), 315302 (2008). doi:10.1088/0957-4484/19/31/315302'
        },

    ]
    const PatentsList = [
        {
            id: 0,
            comment: '门涌帆，液滴生成装置、设备、方法及原位反应方法，2022/10/27，中国，CN 202211323681.6；'
        },
        {
            id: 1,
            comment: '门涌帆，液滴生成装置，2022/10/27，中国，CN202211323611.0；'
        },
        {
            id: 2,
            comment: '门涌帆，液滴生成装置和设备，2022/10/27，中国，申请号CN202222871288.2，已经获得授权，证书号19202622；'
        }, {
            id: 3,
            comment: '门涌帆、孔维俊，微流控电喷雾装置，2022/05/30，中国，申请号CN202221331901.5，已经获得授权，证书号18504301；'
        }, {
            id: 4,
            comment: '门涌帆、孔维俊，聚合物微球制备装置和制备方法，2022/05/30，中国，CN202210602401.9，已经获得授权，证书号6454601；'
        }, {
            id: 5,
            comment: '门涌帆、孔维俊，数字PCR检测方法、系统和计算机可读存储介质，2021/07/05，中国，CN202110757605.5；2021/12/17，WIPO，PCT/CN2021/139301；'
        },
        {
            id: 6,
            comment: '门涌帆、王冠博、李雯、柴胡玲潇，用于质谱仪的上样装置，2021/05/28，中国，申请号CN202110594684.2，已经获得授权，证书号5590872；'
        },
        {
            id: 7,
            comment: '门涌帆、李雯、吴碧珠，一种嵌套结构及制造其的模具和液滴产生装置, 2020-12-29, 中国, 申请号CN202022616684.1，已经获得授权，证书号12234876；'
        },
        {
            id: 8,
            comment: '门涌帆、李雯、吴碧珠，一种微通道阵列板的嵌套结构的制备方法和液滴产生装置, 2020-11-13, 中国，申请号CN202011266785.9，已经获得授权，证书号6064710；'
        }, {
            id: 9,
            comment: '门涌帆、李雯、吴碧珠，一种用于液体产生装置中的嵌套结构的制备方法, 2020-11-13, 中国，申请号CN202011266792.9，已经获得授权，证书号6164462；'
        }, {
            id: 10,
            comment: '陈艳、门涌帆、舒伟良、吴碧珠，一种微流液滴生成装置，2019-07-30，中国，CN201910695434.0'
        }, {
            id: 11,
            comment: '门涌帆、陈艳、冯鸿涛、敖婷婷，基于微流控电喷雾的单细胞全基因组扩增系统和方法, 2019-7-12,WIPO, PCT/CN2019/095797；中国，CN201910628637.8；'
        }, {
            id: 12,
            comment: '门涌帆、敖婷婷、李致昊、吴碧珠、陈艳，基于微流控液滴打印系统的数字PCR检测方法及应用, 2019-7-8, WIPO, PCT/CN2019/094987；中国，申请号CN201910608487.4，已经获得授权，证书号6107668；'
        },
        {
            id: 13,
            comment: '罗茜、任松磊、门涌帆、李芳、黄浩、敖婷婷、喻学峰，用于脂质化合物检测的微流控芯片和脂质化合物的检测方法, 2018-12-18, 中国, CN201811547308.2；'
        },
        {
            id: 14,
            comment: 'Hsueh-Chia Chang, David B. Go, Zdenek Slouka, Satyajyoti Senapati, Yongfan Men, Zehao Pan, AC electrosprayed droplets for digital and emulsion PCR, 2017-5-9, 美国, PCT/US17/31715, US11293057B2；'
        }, {
            id: 15,
            comment: '黄岩谊、费鹏、赫滋、郑春红、陈涛、门涌帆，基于微流控学的数字可调式微镜芯片及其制备方法，2011-06-24，中国，CN201110172533.4；'
        },
    ]
    const FundingList = [
        {
            id: 0,
            comment: 'DNA勘验核心装备自主研制及可控应用关键技术研究（2021YFC3300100）、2021.11-2024.10、国家科技部、合作单位负责人、113.4万；'
        },
        {
            id: 1,
            comment: '全自动微滴式数字PCR系统关键技术研发、2021.04-2024.04、深圳市科创委技术攻关重点项目、合作单位负责人、240万；'
        },
        {
            id: 2,
            comment: '基于微流控-质谱联用技术研究kindlin在肺癌Wnt信号通路中的作用机制、2020.11-2023.10、深圳湾实验室开放基金、主持人、200万；'
        }, {
            id: 3,
            comment: '基于微流控技术的细胞分选仪器及配套芯片技术开发项目、2020.03-2022.02、广东凯普生物科技股份有限公司、主持人、296万+40万；'
        }, {
            id: 4,
            comment: '适用于单细胞全基因组扩增的微流控交流电喷雾方法研究、2020.01-2022.12、国家自然科学基金、青年科学基金、主持人、26万；'
        }, {
            id: 5,
            comment: '基于微流控技术的肿瘤微环境细胞间Wnt信号互作的研究、2019.03-2022.03、深圳市科创委学科布局、主持人、150万；'
        },
        {
            id: 6,
            comment: '基于电喷雾原理的超微液滴发生微机电系统研究、2019.03-2021.03、深圳市科创委自由探索、主持人、30万；'
        },
        {
            id: 7,
            comment: 'GOSO体感创新联合实验室、2022.03-2025.03、广东启悦未来科技股份有限公司、核心成员、30万；'
        },
        {
            id: 8,
            comment: '数字微流控联合实验室、2018.08-2020.07、上海睿昂生物技术有限公司、核心成员、75万；'
        }, {
            id: 9,
            comment: '哺乳动物早期胚胎体外培养系统、2023.06-2023.10、中国科学院空间应用工程与技术中心、核心成员、110万；'
        }, {
            id: 10,
            comment: '空间组织培养环境架构系统、2023.06-2023.10、中国科学院空间应用工程与技术中心、核心成员、51.28万'
        },
    ]
    let numStatus = 0;

    return (
        <div className="header_div_member">
            <Container>
                <Row>
                    <Col md={3}>
                        <div className={'div-fixed'}>
                            <ListGroup as="ul">
                                {
                                    listData.map((item, index) => {
                                        return (
                                            <ListGroup.Item action key={index} active={listStatus === index}
                                                            onClick={() => {
                                                                setListStatus(index)
                                                            }}>
                                                {item}
                                            </ListGroup.Item>
                                        )
                                    })
                                }

                            </ListGroup>
                        </div>
                    </Col>
                    <Col md={9}>
                        <Col>
                            <Row hidden={listStatus !== 0}>
                                <Col md={6}>
                                    <Image src={'/images/boss2.png'}/>
                                </Col>
                                <Col md={5} style={{padding: '1rem'}}>
                                    <p>Principal Investigator:</p>
                                    <h3>Dr. Henry Yongfan Men</h3>
                                    <p>Associate Researcher of Shenzhen Institute of Advanced Technology</p>
                                    <br/>
                                    <p>
                                        PhD in Mechanical Engineering, Peking University (China) 2012
                                        <br/>
                                        Bachelor of Science in Applied Physics, Jilin University (China) 2007
                                    </p>
                                    <br/>
                                    <p>
                                        Office: 7th floor, No. 1068 Xueyuan Avenue, Shenzhen University Town, Xili,
                                        Nanshan District, Shenzhen, China
                                        <br/>
                                        Phone: +86-18611230987
                                        <br/>
                                        Email：yf.men@siat.ac.cn
                                    </p>

                                </Col>
                                <Col>
                                    <hr/>
                                    <h4>Working Experience and Positions:</h4>
                                    <ListGroup as="ol">
                                        <ListGroup.Item as="li">9/2017-present    &nbsp;&nbsp;Associate Professor:
                                            Shenzhen Institutes of Advanced Technology, Chinese Academy of
                                            Sciences</ListGroup.Item>
                                        <ListGroup.Item as="li">9/2018-9/2019    &nbsp;&nbsp;Visiting Scholar:
                                            University of California, Berkeley, Department of
                                            Bioengineering</ListGroup.Item>
                                        <ListGroup.Item as="li">10/2016-8/2017    &nbsp;&nbsp;Associate Specialist:
                                            University of California, Davis, College of Engineering, Department of
                                            Biomedical Engineering</ListGroup.Item>
                                        <ListGroup.Item as="li">10/2014-10/2016    &nbsp;&nbsp;Postdoctoral Fellow:
                                            University of Notre Dame, Department of Chemical and Biomolecular
                                            Engineering </ListGroup.Item>
                                        <ListGroup.Item as="li">7/2012—9/2014    &nbsp;&nbsp;Postdoctoral Fellow: Peking
                                            University, School of Life Sciences </ListGroup.Item>
                                        <ListGroup.Item as="li">9/2007—7/2012    &nbsp;&nbsp;Graduate Student: Peking
                                            University, College of Engineering, Department of Materials Science and
                                            Engineering</ListGroup.Item>
                                        <ListGroup.Item as="li">9/2009—1/2010    &nbsp;&nbsp;Teaching Assistant: Peking
                                            University, College of Engineering, Department of Materials Science and
                                            Engineering</ListGroup.Item>
                                        <ListGroup.Item as="li">13/2006—8/2007    &nbsp;&nbsp;Research Assistant: Jilin
                                            University, State Key Laboratory of Super Hard Materials </ListGroup.Item>
                                        <ListGroup.Item as="li">7/2005—2/2006    &nbsp;&nbsp;Research Assistant:
                                            National Academy of Science, Advanced carbon division in Shenyang National
                                            Laboratory for Materials Science </ListGroup.Item>
                                    </ListGroup>
                                    <hr/>
                                    <h4>Awards:</h4>
                                    <h5>第二十四届高交会 优秀产品奖</h5>
                                    <hr/>
                                    <h4>Publications:</h4>
                                    <ListGroup as="ol" numbered>
                                        {
                                            publicationsList.map((item, index) => {
                                                return <ListGroup.Item as="li">{item.comment}</ListGroup.Item>
                                            })

                                        }
                                    </ListGroup>
                                    <hr/>
                                    <h4>Patents:</h4>
                                    <ListGroup as="ol" numbered>
                                        {
                                            PatentsList.map((item, index) => {
                                                return <ListGroup.Item as="li">{item.comment}</ListGroup.Item>
                                            })

                                        }
                                    </ListGroup>
                                    <hr/>
                                    <h4>Funding:</h4>
                                    <ListGroup as="ol" numbered>
                                        {
                                            FundingList.map((item, index) => {
                                                return <ListGroup.Item as="li">{item.comment}</ListGroup.Item>
                                            })

                                        }
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    membersList.map((item, index) => {

                                        if (listStatus === item.positionID) {
                                            numStatus++;
                                            return <Col md={4} key={item.name} hidden={
                                                listStatus !== item.positionID || listStatus === 0
                                            }>
                                                <Card className="card_member"
                                                      style={{height: item.positionID !== 6 ? '28rem' : '15rem'}}>
                                                    {item.positionID !== 6 ?
                                                        <Card.Img variant="top" src={item.img}/> : ''}
                                                    <Card.Body>
                                                        <Card.Title
                                                            style={{padding: item.positionID !== 6 ? '0rem' : '2rem 0 1rem 0'}}>
                                                            <Button variant="primary" className="card_btn"
                                                                    onClick={() => {
                                                                        setMemberStatus(index)
                                                                        handleShow()
                                                                    }}>{item.name}</Button></Card.Title>
                                                        <pre
                                                            className="content">{item.education ? item.education : ''}</pre>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        }
                                        if (index === membersList.length - 1) {
                                            if (numStatus === 0) {
                                                return <div style={{padding: '0 0 20rem 0'}}>
                                                </div>
                                            } else {
                                                numStatus = 0;
                                            }
                                        }
                                    })

                                }
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Modal show={modelShow} onHide={handleClose} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{listData[membersList[memberStatus].positionID]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>

                        {membersList[memberStatus].positionID !== 6 ? <Col md={4} sm={6}>
                            <Image src={membersList[memberStatus].img} width="100%"
                                   className="img-thumbnail"/></Col> : ''}

                        <Col md={membersList[memberStatus].positionID !== 5 ? '8' : '12'} sm={12}>
                            <h5>Name</h5>
                            <p >&nbsp;&nbsp;{membersList[memberStatus].name}</p><br/>
                            {membersList[memberStatus].education && <div>
                                <h5>Education</h5>
                                <pre className="content">{membersList[memberStatus].education}</pre>
                            </div>
                            }
                            <div hidden={membersList[memberStatus].person_experience === ''}>
                                <h5>Person Experience</h5>
                                <pre className="content"
                                >{membersList[memberStatus].person_experience}</pre>
                            </div>

                            {membersList[memberStatus].email && <div>
                                <h5>Email</h5>
                                <pre className="content">  {membersList[memberStatus].email}</pre>
                            </div>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default Members